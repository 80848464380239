<template>
  <div v-if="active" class="collapse-expand" style="overflow: auto;">
    <slot/>
  </div>
</template>
<script>
export default {
  props: {
    expand: {type: Boolean, default: false},
  },
  data() {
    return {
      active: this.expand,
    }
  },
  watch: {
    expand(val, old) {
      if (val === old) return;
      clearTimeout(this.openTimer);
      if (val) {
        this.active = true;
        this.$nextTick(() => {
          this.$el.style.overflow = 'auto';
          this.$el.style.maxHeight = '0px';
          this.openTimer = setTimeout(()=>{
            this.$el.style.maxHeight = this.$el.scrollHeight + 'px';
            this.$el.style.overflow = 'hidden';
            this.openTimer = setTimeout(()=>{
              this.$el.style.maxHeight = 'inherit'
            }, 300)
          })
        })
      } else {
        this.$el.style.maxHeight = this.$el.offsetHeight+'px';
        this.openTimer = setTimeout(()=>{
          this.$el.style.maxHeight = '0px';
          this.openTimer = setTimeout(() => {
            this.active = false;
          }, 300)
        }, 0)
      }
    }
  },
  mounted() {
    if (this.active) {
      this.$el.style.maxHeight = 'inherit';
      this.$el.style.overflow = 'hidden';
    }
  },
  methods: {
    open() {

    }
  }

}
</script>
