<template>
<!--  <div ref="input"-->
<!--       class="tree-title"-->
<!--       :placeholder="placeholder"-->
<!--       contenteditable="true"-->
<!--       @blur="onBlur"-->
<!--       @keydown="onKeydown"-->
<!--       @focus="onFocus">-->
<!--    {{ modelValue }}-->
<!--  </div>-->
  <input
      ref="input"
      :value="modelValue"
      placeholder="请输入名称"
      @blur="onBlur"
      @keydown="onKeydown"
      @focus="onFocus"
  />
</template>

<script>
export default {
  name: "TreeInput",
  props: {
    modelValue: {type: String, default: ''},
    placeholder: {type: String, default: ''},
  },
  methods: {
    focus() {
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    selectAll() {
      if (window.getSelection) {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(this.$refs.input);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    },
    focusEnd() {
      // this.el.focus();
      if (window.getSelection) {
        //ie11 10 9 ff safari
        const range = window.getSelection(); //创建range
        range.selectAllChildren(this.$refs.input); //range 选择box2下所有子内容
        range.collapseToEnd(); //光标移至最后
      }
    },
    onBlur() {
      const text = this.$refs.input.innerText;
      if (this.modelValue !== text && text) {
        this.$emit('save', text)
      } else {
        this.$refs.input.innerText = this.modelValue;
      }
      this.$emit('blur')
    },
    onKeydown(e) {
      if (e.key === "Enter") {
        this.$refs.input.blur();
      }
    },
    onFocus() {
      if (this.modelValue === "新建模型") {
        // this.selectAll();
      } else {
        // this.focusEnd();
      }
    }
  }
}
</script>

<style scoped>

</style>
