<template>
  <div class="color-block">
    <div class="demonstration">主题色</div>
    <el-input v-model="mindStyle.themeColor" style="width: 100px" />
    <el-color-picker v-model="mindStyle.themeColor" />
  </div>
  <div class="color-block">
    <span class="demonstration">主模型字体颜色</span>
    <el-input v-model="mindStyle.btnTextColor" style="width: 100px" />
    <el-color-picker v-model="mindStyle.btnTextColor" />
  </div>
  <div class="color-block">
    <span class="demonstration">子模型背景颜色</span>
    <el-input v-model="mindStyle.secondBgColor" style="width: 100px" />
    <el-color-picker v-model="mindStyle.secondBgColor" />
  </div>
  <div class="color-block">
    <span class="demonstration">整体模型字体颜色</span>
    <el-input v-model="mindStyle.thirdTextColor" style="width: 100px" />
    <el-color-picker v-model="mindStyle.thirdTextColor" />
  </div>
  <div class="color-block">
    <span class="demonstration">关联模型线条颜色</span>
    <el-input v-model="mindStyle.lineThemeColor" style="width: 100px" />
    <el-color-picker v-model="mindStyle.lineThemeColor" />
  </div>
  <div class="dialog-footer">
    <el-button @click="restore"> 默认值 </el-button>
    <el-button type="primary" @click="onSubmit"> 确定 </el-button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      mindStyle: {
        themeColor: "#6BA4FA",
        btnTextColor: "#ebebeb",
        secondBgColor: "#1A1A1A",
        thirdTextColor: "#ebebeb",
        lineThemeColor: "#A48AFF",
      },
    };
  },
  inject: ["changeMindStyle"],
  created() {},
  mounted() {},
  methods: {
    onSubmit() {
      this.changeMindStyle(
        this.mindStyle.themeColor,
        this.mindStyle.btnTextColor,
        this.mindStyle.secondBgColor,
        this.mindStyle.thirdTextColor,
        this.mindStyle.lineThemeColor,
        "custom"
      );
      this.onClose();
    },
    restore() {
      if (this.$root.config.theme.includes("dark")) {
        this.mindStyle = {
          themeColor: "#6BA4FA",
          btnTextColor: "#ebebeb",
          secondBgColor: "#1A1A1A",
          thirdTextColor: "#ebebeb",
          lineThemeColor: "#A48AFF",
        };
      } else {
        this.mindStyle = {
          themeColor: "#404040",
          btnTextColor: "#FFFFFF",
          secondBgColor: "#F5F5F5",
          thirdTextColor: "#666666",
          lineThemeColor: "#A48AFF",
        };
      }
    },
    onClose() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.color-block {
  margin-bottom: 10px;
}
.color-block ::v-deep .el-color-picker {
  margin-left: 20px;
}
.demonstration {
  float: left;
  width: 120px;
  line-height: 32px;
}
.dialog-footer {
  text-align: right;
  padding-bottom: 20px;
  margin: 20px;
}
</style>
