<template>
  <div class="grout-icon">
    <svg-icon
      icon-class="049"
      class-name="mind-all"
      @click="onOpenGroup"
      style="vertical-align: -8%"
    />
    <el-menu
      class="tree-menus right-menu select-group"
      :class="[theme, 'menu-group']"
      v-if="showGroup"
    >
      <el-menu-item index="all" @click="onChangeGroup(null)">
        全部
      </el-menu-item>
      <el-menu-item
        class="checkbox-group-btn"
        :index="index"
        v-for="(item, index) in $root.groups"
        :key="index"
        @click="onChangeGroup(item)"
      >
        <span>
          {{ item.groupName }}
        </span>
      </el-menu-item>
      <li class="el-dropdown-menu__item--divided" style="padding: 0"></li>
      <el-menu-item @click="onAddGroup" index="add"> 添加分组 </el-menu-item>
    </el-menu>
  </div>
</template>
<script>
export default {
  props: {
    isLocal: { type: Boolean, default: false },
    theme: { type: String, default: "" },
  },
  watch: {
    showGroup(val, old) {
      if (val) {
        requestAnimationFrame(() => {
          this.addEvent();
        });
      } else {
        this.removeEvent();
      }
    },
  },
  data() {
    return {
      showGroup: false,
    };
  },
  methods: {
    onOpenGroup() {
      this.showGroup = !this.showGroup;
    },
    onChangeGroup(item) {
      this.$emit("change", item);
      this.showGroup = false;
    },
    onAddGroup() {
      this.$root.configKey += 1;
      this.$root.configActive = "groups";
      this.$root.configVisible = true;
    },
    onClose() {
      this.showGroup = false;
    },
    addEvent() {
      window.addEventListener("click", this.onClose, { once: true });
    },
    removeEvent() {
      window.removeEventListener("click", this.onClose);
    },
  },
};
</script>
