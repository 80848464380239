let _node = [];
let _type = '';
export const cut = (curNode) => {
    _node.push(curNode)
    _type = 'cut'
}
export const copy = (curNode) => {
    _node.push(curNode)
    _type = 'copy'
}
export const getCopiedNodes = () => {
    let res = { nodes: _node, type: _type }
    _node = [];
    _type = '';
    return res
}